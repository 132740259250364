<template>
  <v-footer class="footer-main">
    <div class="svg-wrapper">
      <svg viewBox="0 0 1695 57" preserveAspectRatio="none">
        <path
          d="M0 23c135.4 19 289.6 28.5 462.5 28.5C721.9 51.5 936.7 1 1212.2 1 1395.8.9 1556.7 8.3 1695 23v34H0V23z"
          fill="rgba(255,255,255,1)"
          fill-rule="evenodd"
        />
      </svg>
    </div>
    <v-row align="center" justify="center" class="footer-wrapper">
      <v-col cols="12" sm="10" md="8">
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon.name"
            :href="icon.link"
            class="mx-4 white--text"
            icon
          >
            <v-icon>{{ icon.name }}</v-icon>
          </v-btn>
        </v-card-text>
        <div class="terms-wrapper">
          <v-btn x-small text color="white" to="/privacy">Privacy</v-btn>
        </div>
        <div style="display: flex;">
          <p class="copyright">&copy; {{ new Date().getFullYear() }}</p>
          <p class="credit">
            <span style="padding-left: 5px;">All rights reserved</span>
          </p>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { link: 'http://facebook.com/tubelooper', name: 'mdi-facebook' },
      { link: 'http://twitter.com/tubelooper', name: 'mdi-twitter' },
      { link: 'http://instagram.com', name: 'mdi-instagram' },
    ],
  }),
  computed: {
    date: function() {
      return true;
    },
  },
};
</script>

<style lang="sass" scoped>
.footer-main
  color: #fff
  overflow: hidden
  position: relative
  background: radial-gradient(circle at 10% 20%, rgb(229, 27, 35) 0%, rgb(191, 25, 31) 100.2%) center center / cover no-repeat
  background-repeat: no-repeat
  background-position: center center
.svg-wrapper
  position: absolute
  left: 0px
  width: 100vw
  min-width: 800px
  transform: rotate(180deg) translateY(1px)
  bottom: auto
  top: -8px
.footer-wrapper
  color: #fff
  margin-top: 2rem
  font-size: 14px
  >div
    flex-direction: column
    max-width: 275px
    +flex-ctr()
    justify-content: space-around
  p
    margin-bottom: 0
</style>
