import localForage from 'localforage';

localForage.config({
  version: 1.0,
  storeName: 'loops_storage',
  description: 'storing user videos and loops',
});

const loopsForage = localForage.createInstance({
  name: 'tubeLooper',
});
const otherForage = localForage.createInstance({
  name: 'youtube_repeater_other',
});

export const PASSWORD_RESET_KEY = 'resetPasswordSent';
export const PASSWORD_RESET_TIMEOUT = 3600 * 6 * 1000; // seconds

const loopsStorage = {
  async getItem(key) {
    try {
      return loopsForage.getItem(key);
    } catch (error) {
      console.error('error:', error);
    }
  },
  async setItem(key, value) {
    try {
      await loopsForage.setItem(key, value);
    } catch (error) {
      console.error('error:', error);
    }
  },
  async removeItem(key) {
    try {
      await loopsForage.removeItem(key);
    } catch (error) {
      console.error('error:', error);
    }
  },
  async hasItems() {
    try {
      const keys = await loopsForage.length();
      return keys > 0;
    } catch (error) {
      console.error('error:', error);
    }
  },
  async getAny() {
    try {
      const key = await loopsForage.key(0);
      return { key, video: await loopsForage.getItem(key) };
    } catch (error) {
      console.error('error:', error);
    }
  },
};

const generalStorage = {
  async getItem(key) {
    try {
      return otherForage.getItem(key);
    } catch (error) {
      console.error('error:', error);
    }
  },
  async setItem(key, value) {
    try {
      await otherForage.setItem(key, value);
    } catch (error) {
      console.error('error:', error);
    }
  },
};

export default {
  loopsStorage,
  generalStorage,
};
