<template>
  <v-content>
    <v-container class="wrapper">
      <v-row align="center" justify="center" class="form-wrapper">
        <v-col cols="12" sm="10" md="8">
          <ValidationObserver v-slot="{ passes }">
            <span class="form-hint">Enter video URL:</span>
            <v-form onsubmit="return false;">
              <TextFieldWithValidation
                ref="urlInput"
                :rules="!!user && !newVideoTriggered ? '' : 'required|youtube'"
                label="video-url"
                v-model="videoUrl"
                prepend-icon="mdi-youtube"
                type="text"
                outlined
                placeholder="enter url"
                :disabled="!!user && !newVideoTriggered"
              />
              <div class="load-btn-wrapper">
                <v-dialog v-model="limitMessage" persistent max-width="340">
                  <v-card>
                    <v-card-title style="word-break: break-word;">
                      You reached limit for saved videos
                    </v-card-title>

                    <v-card-text>
                      Free tier supports up to <strong>3</strong> videos saved.
                      In order to add new video, you can delete one of existing
                      ones.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="limitMessage = false">
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  width="150"
                  color="primary"
                  @click.stop="
                    passes(
                      !!user && !newVideoTriggered
                        ? handleAddNewVideo
                        : loadVideo
                    )
                  "
                >
                  {{
                    !!user && !newVideoTriggered ? 'Add new video' : 'Load'
                  }}</v-btn
                >
              </div>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
      <transition name="fade">
        <v-row
          v-if="showPlayer"
          v-bind:class="{ loggedIn: user }"
          class="video-content-wrapper"
          align="center"
          justify="center"
        >
          <span class="form-hint">Create your loops:</span>
          <v-col
            cols="12"
            sm="12"
            md="10"
            ref="playerRef"
            class="player-col-wrapper"
          >
            <VideoPlayer
              :url="sanitizedUrl"
              :bus="bus"
              :externalLoops="loopsFromStorage"
              :onSaveLoops="updateLoops"
            />
          </v-col>
        </v-row>
      </transition>
      <v-row align="center" justify="center">
        <span class="form-hint">
          Browse your looped videos:
        </span>
        <VideosList :user="user" :onSelect="handleVideoSelect" />
      </v-row>
    </v-container>
  </v-content>
</template>
<script>
import Vue from 'vue';
import { ValidationObserver, extend } from 'vee-validate';
import scrollIntoView from 'scroll-into-view';
import TextFieldWithValidation from '@/components/BaseTextFieldWIthValidation';
import VideosList from '@/components/VideosList';
import storage from '@/store/localStore';
import { mapState, mapActions } from 'vuex';
import {
  extractYotubeId,
  generateUrlFromId,
  getLoopsFromLocalStorage,
} from '../components/Looper/utils';

extend('youtube', value => {
  return /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm.test(value);
});

const MAX_VIDEO_AMOUNT = 3;
const { loopsStorage } = storage;

export default {
  name: 'app-looper',
  components: {
    ValidationObserver,
    TextFieldWithValidation,
    VideosList,
    VideoPlayer: () =>
      import(/* webpackPrefetch: true */ '@/components/Looper/VideoPlayer'),
  },
  data: () => ({
    videoUrl: '',
    currentUrl: '',
    loopsFromStorage: null,
    showPlayer: false,
    newVideoTriggered: false,
    limitMessage: false,
    bus: new Vue(),
  }),
  computed: {
    ...mapState({
      user: state => state.auth.user,
      currentPlaying: state => state.loops.currentVideoLoops,
      userVideos: state => state.loops.userVideos,
    }),
    isVideoLimitReached() {
      return this.userVideos.length === MAX_VIDEO_AMOUNT;
    },
    sanitizedUrl() {
      return this.videoUrl
        ? generateUrlFromId(extractYotubeId(this.videoUrl))
        : '';
    },
  },
  watch: {
    async currentPlaying(newVal, oldVal) {
      // load on mount for authorized flow
      if (!this.user || newVal.url === oldVal.url) return;
      if (newVal) {
        if (!newVal.url) {
          this.resetAndFocusInput();
        } else {
          this.handleVideoSelect(newVal.url, newVal.loops);
        }
      }
    },
    user: function(newUser) {
      // on logout
      if (newUser === null) {
        this.resetAndFocusInput();
      }
    },
  },
  methods: {
    loadVideo() {
      if (!this.user) {
        return this.loadVideoUnauthorized();
      } else {
        // check if video is in the list
        const knownVideo = this.userVideos.find(el => {
          return el.youtubeId === extractYotubeId(this.videoUrl);
        });
        if (knownVideo) {
          this.updateCurrentPlayingInDb(knownVideo.youtubeId);
          this.readVideoLoopsFromDb({ videoId: knownVideo.youtubeId });
        } else {
          this.loopsFromStorage = null;
        }
        this.loadVideoAuthorized();
      }
      this.newVideoTriggered = false;
    },
    handleAddNewVideo() {
      if (this.user.activePlan !== 'TEST' && this.isVideoLimitReached) {
        this.limitMessage = true;
        return;
      }
      this.newVideoTriggered = true;
      this.resetAndFocusInput();
    },
    resetAndFocusInput() {
      this.videoUrl = '';
      this.currentUrl = '';
      this.loopsFromStorage = null;
      this.showPlayer = false;
      if (this.currentPlaying.url) {
        this.$store.commit('SET_CURRENT_VIDEO', {
          url: '',
          loops: [],
        });
      }
      this.$nextTick(() => this.$refs['urlInput'].$refs.input.focus());
    },
    delayedScrollIntoView(cb) {
      this.$nextTick(() => {
        cb && cb();
        this.$nextTick(() => {
          scrollIntoView(this.$refs.playerRef, { time: 750 });
        });
      });
    },
    loadVideoUnauthorized() {
      if (!this.showPlayer) {
        this.currentUrl = this.videoUrl;
        if (!this.loopsFromStorage) {
          this.saveToStorage(this.videoUrl);
        }
        this.showPlayer = true;
        this.delayedScrollIntoView();
      } else {
        if (this.currentUrl === this.videoUrl) return;
        this.showPlayer = false;

        // clear loop
        this.removeFromStorage(this.currentUrl);
        this.loopsFromStorage = null;
        this.clearLoop();

        // set new player
        this.currentUrl = this.videoUrl;
        this.delayedScrollIntoView(() => {
          this.showPlayer = true;
          this.saveToStorage(this.videoUrl);
        });
      }
    },
    loadVideoAuthorized() {
      // update current loops
      if (!this.showPlayer) {
        this.currentUrl = this.videoUrl;
        this.showPlayer = true;
        this.delayedScrollIntoView();
      } else {
        if (this.currentUrl === this.videoUrl) return;
        this.showPlayer = false;
        // set new player
        this.currentUrl = this.videoUrl;
        this.delayedScrollIntoView(() => {
          this.showPlayer = true;
        });
      }
    },
    handleVideoSelect(url, loops, youtubeId) {
      this.videoUrl = url;
      this.loopsFromStorage = loops;
      // case for selecting video from the list
      if (youtubeId) {
        this.updateCurrentPlayingInDb(youtubeId);
      }
      this.loadVideo();
    },
    updateLoops(videoUrl, loopsObj) {
      if (this.user) {
        this.updateLoopsInDb({ videoUrl, loops: loopsObj });
      } else {
        loopsStorage.setItem(videoUrl, loopsObj);
      }
    },
    saveToStorage(key) {
      loopsStorage.setItem(key, { loops: [], active: null, duration: 0 });
    },
    removeFromStorage(key) {
      loopsStorage.removeItem(key);
    },
    clearLoop() {
      this.bus.$emit('clearLoop');
    },
    ...mapActions([
      'updateCurrentPlayingInDb',
      'updateLoopsInDb',
      'readVideoLoopsFromDb',
    ]),
  },
  mounted: async function() {
    this.$refs['urlInput'] = this.$refs['urlInput'].$refs['baseInput'];
    let data;
    // if authorized but no selected video - do nothing
    if (this.user) {
      if (!this.currentPlaying) return;
    } else {
      // unauthorized
      data = await getLoopsFromLocalStorage();
    }
    if (!data) return;
    // load on mount unauthorized
    this.handleVideoSelect(data.url, data.loops);
  },
};
</script>
<style lang="sass" scoped>
.wrapper
  max-width: 1400px
  margin: 0 auto
  +flex-ctr()
  flex-direction: column
  >div
    flex-direction: column
    .h1-seo
      visibility: hidden
      height: 1px
  .looper-logo
    min-height: 30vh
    max-height: 300px
    max-width: 500px
    @include mq("tablet", "max")
      max-width: 95vw
  .form-wrapper
    width: 100%
    font-size: 1.5rem
    .load-btn-wrapper
      +flex-ctr()
  .form-hint
    margin-bottom: 15px
    margin-top: 15px
    padding: 0 16px
    display: block
    text-align: center
    font-size: 1.2rem
  .video-content-wrapper
    width: 100%
    padding-top: 3rem
    padding-bottom: 5rem
    display: flex
    flex-direction: column
    .player-col-wrapper
      @include mq("tablet", "max")
        padding: 0
  .video-content-wrapper.loggedIn
    padding-bottom: 1rem
    @include mq("tablet", "max")
      padding-bottom: 7rem
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s
  transition-delay: 0.25s
.fade-enter, .fade-leave-to
  opacity: 0
</style>
