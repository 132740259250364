import Vue from 'vue';
import VueRouter from 'vue-router';

import Looper from '@/views/Looper.vue';
import { firebase } from '@/plugins/firebase';
import vueStore from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'root',
    component: Looper,
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      isLoginRoute: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Signup.vue'),
  },
  {
    path: '/reset',
    name: 'reset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/ResetEmail.vue'),
  },
  { path: '/*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let isLoginRoute = to.matched.some(record => record.meta.isLoginRoute);

  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        next({
          path: '/login',
        });
      } else {
        next();
      }
    });
  } else if (isLoginRoute) {
    if (vueStore.state.auth.user) {
      next({
        path: '/',
      });
    } else next();
  } else {
    next();
  }
});

export default router;
