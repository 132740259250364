import Vue from 'vue';
import VueResource from 'vue-resource';
import VuePortal from '@linusborg/vue-simple-portal';

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(VuePortal);

Vue.http.options.emulateJSON = true;
const http = Vue.http;
export { http, Vue as vm };
