<template>
  <v-navigation-drawer v-model="open" fixed bottom temporary>
    <template v-if="user">
      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar color="primary">
            <span class="white--text headline">{{
              user.name.slice(0, 1)
            }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user && user.name }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item to="/account">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Browse account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-if="user" v-slot:append>
      <div class="pa-2">
        <v-btn block color="secondary" @click="logout">
          Log Out
          <v-icon right dark>mdi-exit-to-app</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-else v-slot:prepend>
      <div class="pa-2">
        <v-btn block v-if="isLoginRoute" to="/">Home</v-btn>
        <v-btn block v-else to="/login">Login</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'app-nav-drawer-menu',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    toggle: Function,
  },
  data: () => ({
    isLoginRoute: false,
  }),
  watch: {
    $route(to) {
      if (to.name === 'login') {
        this.isLoginRoute = true;
      } else {
        this.isLoginRoute = false;
      }
    },
  },
  computed: {
    open: {
      get() {
        return this.isOpen;
      },
      set(val) {
        this.$emit('update:isOpen', val);
      },
    },
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
