import { firestoreAction } from 'vuexfire';
import { firebase, db } from '@/plugins/firebase';
import {
  extractYotubeId,
  generateUrlFromId,
  getYtThumbnail,
} from '@/components/Looper/utils';

const YOUTUBE_API_KEY = 'AIzaSyBQ4HAycD6JMUfqFGHCQaOrtWKnslG7Z9M';

// initial state
const state = {
  userVideos: [],
  fetchingVideosData: false,
  currentVideoLoops: {
    url: '',
    loops: [],
  },
};

// getters
const getters = {};

// actions
const actions = {
  saveVideoToDb: firestoreAction(async (_, { uid, videoId }) => {
    const docRef = db.collection('users').doc(uid);
    await docRef.update({
      videos: firebase.firestore.FieldValue.arrayUnion(videoId),
    });
  }),
  updateCurrentPlayingInDb: firestoreAction(
    async ({ rootState: { auth } }, videoId) => {
      const docRef = db.collection('users').doc(auth.user.userId);
      await docRef.update({
        currentPlaying: videoId,
      });
    }
  ),
  deleteVideoFromDb: firestoreAction(
    async ({ rootState: { auth }, state, commit }, videoId) => {
      const isCurrentVideo =
        state.currentVideoLoops.url &&
        extractYotubeId(state.currentVideoLoops.url) === videoId;
      const userRef = db.collection('users').doc(auth.user.userId);
      const loopsRef = db
        .collection('loops')
        .doc(`${auth.user.userId}__${videoId}`);

      await Promise.all([
        userRef.update({
          [`videos.${videoId}`]: firebase.firestore.FieldValue.delete(),
          ...(isCurrentVideo && {
            currentPlaying: '',
          }),
        }),
        loopsRef.delete(),
      ]);
      // remove from state
      const newVids = [...state.userVideos];
      const delIdx = newVids.findIndex(el => el.youtubeId === videoId);
      newVids.splice(delIdx, 1);
      commit('SET_VIDEOS', newVids);
      if (isCurrentVideo) {
        commit('SET_CURRENT_VIDEO', {
          url: '',
          loops: [],
        });
      }
    }
  ),
  updateLoopsInDb: firestoreAction(
    async (
      { rootState: { auth }, state, commit },
      { videoUrl, loops: { loops, active, duration } }
    ) => {
      const oldLoops = state.currentVideoLoops.loops.loops;
      const vidId = extractYotubeId(videoUrl);
      const loopsRef = await db
        .collection('loops')
        .doc(`${auth.user.userId}__${vidId}`);

      const docCheck = await loopsRef.get();
      if (!docCheck.exists) {
        await loopsRef.set({ loops, active, duration });
      } else {
        await loopsRef.update({
          loops,
          active,
          duration,
        });
      }
      const userRef = db.collection('users').doc(auth.user.userId);
      const loopsQtyChanged = !oldLoops || oldLoops.length !== loops.length;
      await userRef.update({
        currentPlaying: vidId,
        ...(loopsQtyChanged && {
          [`videos.${vidId}.loops`]: loops.length,
        }),
      });
      // check if video is the list, if not - add it
      if (
        state.userVideos &&
        !state.userVideos.find(el => el.youtubeId === vidId)
      ) {
        const newVids = [...state.userVideos];
        const ytData = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${vidId}&fields=items(id,snippet)&key=${YOUTUBE_API_KEY}`
        );
        const res = await ytData.json();
        if (res.items && res.items.length) {
          const ytData = res.items[0];
          newVids.push({
            youtubeId: vidId,
            loops: loops.length,
            thumbnail: getYtThumbnail(ytData.snippet.thumbnails),
            title: ytData.snippet.title,
            channelName: ytData.snippet.channelTitle,
          });
        }
        commit('SET_VIDEOS', newVids);
        commit('SET_CURRENT_VIDEO', {
          url: videoUrl,
          loops: { loops, active, duration },
        });
      }
    }
  ),
  async getVideoDetails({ commit }, videosArr) {
    const idsArr = videosArr.map(el => el.youtubeId);
    const query = idsArr.join(',');
    const mergedArr = [];
    const ytData = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${query}&fields=items(id,snippet)&key=${YOUTUBE_API_KEY}`
    );
    const res = await ytData.json();
    if (res.items && res.items.length) {
      res.items.forEach(vid => {
        if (idsArr.includes(vid.id)) {
          mergedArr.push({
            youtubeId: vid.id,
            loops: videosArr.find(el => el.youtubeId === vid.id).loops,
            thumbnail: getYtThumbnail(vid.snippet.thumbnails),
            title: vid.snippet.title,
            channelName: vid.snippet.channelTitle,
          });
        }
      });
    }
    commit('SET_VIDEOS', mergedArr);
  },

  async readVideoLoopsFromDb({ rootState: { auth }, commit }, { videoId }) {
    const res = await db
      .collection('loops')
      .doc(`${auth.user.userId}__${videoId}`)
      .get();
    const loopsRes = res.data();
    const loopsData = loopsRes || {
      loops: [],
      active: null,
      duration: null,
    };
    commit('SET_CURRENT_VIDEO', {
      url: generateUrlFromId(videoId),
      loops: loopsData,
    });
  },

  async readVideoFromDb({ dispatch }, userId) {
    const snapshot = await db
      .collection('users')
      .doc(userId)
      .get();
    if (!snapshot) return;
    const doc = snapshot.data();
    if (!doc) return;
    const { videos, currentPlaying } = snapshot.data();
    if (!videos) return;
    const convertedVids = Object.entries(videos).map(arr => ({
      loops: arr[1].loops,
      youtubeId: arr[0],
    }));
    dispatch('getVideoDetails', convertedVids);
    if (currentPlaying) {
      dispatch('readVideoLoopsFromDb', { videoId: currentPlaying });
    }
  },
};

const mutations = {
  SET_VIDEOS(state, data) {
    state.userVideos = data;
  },
  SET_CURRENT_VIDEO(state, data) {
    state.currentVideoLoops = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
