import storage from '@/store/localStore';

export const defaultFormatter = function(seconds, guide = seconds) {
  if (typeof seconds !== 'number') return 'not set';
  seconds = seconds < 0 ? 0 : seconds;
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = m = s = '-';
  }

  // Check if we need to show hours
  h = h > 0 || gh > 0 ? h + ':' : '';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = ((h || gm >= 10) && m < 10 ? '0' + m : m) + ':';

  // Check if leading zero is need for seconds
  s = s < 10 ? '0' + s : s;

  return h + m + s;
};

export const extractYotubeId = url => {
  const match = url.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  );
  return match[1] || null;
};

export const generateUrlFromId = id => {
  return `https://www.youtube.com/watch?v=${id}`;
};

export const getYtThumbnail = thumbnailObj => {
  if (thumbnailObj.maxres) {
    return thumbnailObj.maxres.url;
  }
  if (thumbnailObj.high) {
    return thumbnailObj.high.url;
  }

  return thumbnailObj.default.url;
};

export const getLoopsFromLocalStorage = async () => {
  const hasVideo = await storage.loopsStorage.hasItems();
  if (hasVideo) {
    const data = await storage.loopsStorage.getAny();
    if (data) {
      return {
        url: data.key,
        loops: data.video,
      };
    }
  } else return null;
};
