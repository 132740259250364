import { Either } from '@sweet-monads/either';
import { http, vm } from '@/plugins/vueResource';

const cancelOptions = {
  before(request) {
    if (vm.previousRequest) {
      vm.previousRequest.abort();
    }
    vm.previousRequest = request;
  },
};
class ServerApi {
  async getUsers() {
    try {
      const data = await http.get(
        'https://jsonplaceholder.typicode.com/users',
        cancelOptions
      );
      return Either.right(data);
    } catch (e) {
      return Either.left(new Error(e.message));
    }
  }
}

export const api = new ServerApi();
