import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCOY9fN0ugfEcjYDqz2wteXkoE9x32u8Co',
  authDomain: 'vue-app-f12e3.firebaseapp.com',
  databaseURL: 'https://vue-app-f12e3.firebaseio.com',
  projectId: 'vue-app-f12e3',
  storageBucket: 'vue-app-f12e3.appspot.com',
  messagingSenderId: '26444313248',
  appId: '1:26444313248:web:d727dbdefee634beab0fc5',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { db, firebase };
