<template>
  <!-- <v-app-bar absolute flat style="background-color: transparent;"> -->
  <!-- <v-spacer></v-spacer> -->

  <portal selector="#btn-portal">
    <div class="global-btn-wrapper">
      <v-btn
        v-if="user"
        small
        color="primary"
        to="/account"
        class="hidden-sm-and-down"
        style="margin-right: 5px"
        >Account</v-btn
      >
      <v-btn
        small
        color="secondary"
        @click.stop="toggleSidebar"
        class="hidden-sm-and-up"
        >Options</v-btn
      >
      <template v-if="user">
        <span class="mr-4 hidden-sm-and-down username">{{
          user && `Logged in as ${user.name}`
        }}</span>
        <v-btn
          small
          color="secondary"
          @click="logout"
          class="hidden-sm-and-down"
        >
          Log Out
          <v-icon right dark>mdi-exit-to-app</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          v-if="isLoginRoute"
          small
          color="secondary"
          to="/"
          class="hidden-sm-and-down"
          >Home</v-btn
        >
        <v-btn
          v-else
          small
          color="secondary"
          to="/login"
          class="hidden-sm-and-down"
          >Login</v-btn
        >
      </template>
    </div>
  </portal>
  <!-- </v-app-bar> -->
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'app-main-bar',
  props: {
    toggleSidebar: Function,
  },
  data: () => ({
    isLoginRoute: false,
  }),
  watch: {
    $route(to) {
      if (to.name === 'login') {
        this.isLoginRoute = true;
      } else {
        this.isLoginRoute = false;
      }
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
<style lang="sass">
.global-btn-wrapper
  position: fixed
  bottom: 35px
  right: 35px
  .hidden-sm-and-up
    @include mq("tablet-small")
      display: none !important
  .hidden-sm-and-down
    @include mq("tablet-small", "max")
      display: none !important
  .username
    position: absolute
    bottom: -20px
    right: 0
</style>
