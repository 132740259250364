import Vue from 'vue';
import { vuexfireMutations } from 'vuexfire';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import auth from './modules/auth';
import ui from './modules/ui';
import users from './modules/users';
import loops from './modules/loops';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    users,
    ui,
    loops,
  },
  mutations: {
    ...vuexfireMutations,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
