import { api } from '@/utils';

// initial state
const state = {
  users: [],
  fetchingUsers: false,
};

// getters
const getters = {};

// actions
const actions = {
  async fetchUsers({ commit, dispatch }) {
    commit('CHANGE_FETCH_USERS_STATUS', true);
    const response = await api.getUsers();
    response
      .mapLeft(err => {
        commit('CHANGE_FETCH_USERS_STATUS', false);
        dispatch('ui/triggerErrorMessage', err.message);
      })
      .mapRight(data => {
        commit('SET_USERS', data.body);
        commit('CHANGE_FETCH_USERS_STATUS', false);
      });
  },
};

// mutations
const mutations = {
  SET_USERS(state, data) {
    state.users = data;
  },
  CHANGE_FETCH_USERS_STATUS(state, status) {
    state.fetchingUsers = status;
  },
  CLEAR_USERS(state) {
    state.users = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
