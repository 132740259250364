import { firestoreAction } from 'vuexfire';
import { firebase, db } from '@/plugins/firebase';
import storage from '@/store/localStore';

import router from '@/router';
import { PASSWORD_RESET_KEY } from '../localStore';
import {
  getLoopsFromLocalStorage,
  extractYotubeId,
} from '@/components/Looper/utils';
// initial state
const state = {
  user: null,
  fetchingUserData: false,
};

// getters
const getters = {};

const { generalStorage, loopsStorage } = storage;
// actions
const actions = {
  async signupUserWithEmail({ commit, dispatch }, { email, password, name }) {
    commit('CHANGE_FETCH_USER_STATUS', true);
    try {
      const createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      commit('CHANGE_FETCH_USER_STATUS', false);
      // add video from local storage to DB
      const localVideoData = await getLoopsFromLocalStorage();
      const videoId = extractYotubeId(localVideoData.url);

      await dispatch('saveUserToDb', {
        uid: createdUser.user.uid,
        email: createdUser.user.email,
        userName: name,
        ...(videoId && {
          currentPlaying: videoId,
          videos: {
            [videoId]: {
              loops:
                localVideoData.loops && localVideoData.loops.loops
                  ? localVideoData.loops.loops.length
                  : 0,
            },
          },
        }),
      });
      if (localVideoData.loops) {
        const { loops, active, duration } = localVideoData.loops;
        await db
          .collection('loops')
          .doc(`${createdUser.user.uid}__${videoId}`)
          .set({ loops, active, duration });
      }
      // clear local storage
      await loopsStorage.removeItem(localVideoData.url);
      router.replace('/');
      dispatch('ui/triggerSuccessMessage', `Welcome, ${name}!`);
    } catch (err) {
      commit('CHANGE_FETCH_USER_STATUS', false);
      dispatch('ui/triggerErrorMessage', err.message);
    }
  },
  async loginUserWithEmail({ commit, dispatch }, { email, password }) {
    commit('CHANGE_FETCH_USER_STATUS', true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      commit('CHANGE_FETCH_USER_STATUS', false);
      router.replace('/');
    } catch (err) {
      commit('CHANGE_FETCH_USER_STATUS', false);
      dispatch('ui/triggerErrorMessage', err.message);
    }
  },
  async sendResetEmail({ commit, dispatch }, { email }) {
    commit('CHANGE_FETCH_USER_STATUS', true);
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      commit('CHANGE_FETCH_USER_STATUS', false);
      generalStorage.setItem(PASSWORD_RESET_KEY, {
        email,
        date: Date.now(),
      });
    } catch (err) {
      commit('CHANGE_FETCH_USER_STATUS', false);
      dispatch('ui/triggerErrorMessage', err.message);
    }
  },
  async logout({ commit, dispatch }) {
    try {
      await firebase.auth().signOut();
      commit('SET_USER', null);
    } catch (err) {
      dispatch('ui/triggerErrorMessage', err.message);
    }
  },
  saveUserToDb: firestoreAction(async (_, { uid, ...rest }) => {
    return db
      .collection('users')
      .doc(uid)
      .set({ ...rest }, { merge: true });
  }),
  bindUser: firestoreAction(async ({ bindFirestoreRef }, userId) => {
    return bindFirestoreRef('user', db.collection('users').doc(userId), {
      serialize: snapshot => {
        const data = snapshot.data();
        return {
          name: data.userName,
          email: data.email,
          userId,
          activePlan: data.activePlan,
        };
      },
    });
  }),
  async readUserFromDb({ commit }, userId) {
    const snapshot = await db
      .collection('users')
      .doc(userId)
      .get();
    commit('SET_USER', snapshot.data().userName);
  },
};

// mutations
const mutations = {
  SET_USER(state, userData) {
    state.user = userData;
  },
  CHANGE_FETCH_USER_STATUS(state, status) {
    state.fetchingUserData = status;
  },
  REMOVE_USER(state) {
    state.user = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
