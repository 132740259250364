// initial state
const state = {
  showSuccess: null,
  showError: null,
};

// getters
const getters = {};

// actions
const actions = {
  triggerSuccessMessage: ({ commit }, message) => {
    commit('SET_SUCCESS_MESSSAGE', message);
    setTimeout(() => {
      commit('CLEAR_SUCCESS_MESSSAGE');
    }, 1500);
  },
  triggerErrorMessage: ({ commit }, message) => {
    commit('SET_ERROR_MESSAGE', message);
    setTimeout(() => {
      commit('CLEAR_ERROR_MESSSAGE');
    }, 1500);
  },
};

// mutations
const mutations = {
  SET_SUCCESS_MESSSAGE(state, msg) {
    state.showSuccess = msg;
  },
  CLEAR_SUCCESS_MESSSAGE(state) {
    state.showSuccess = null;
  },
  SET_ERROR_MESSAGE(state, msg) {
    state.showError = msg;
  },
  CLEAR_ERROR_MESSSAGE(state) {
    state.showError = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
