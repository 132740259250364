<template>
  <v-snackbar v-model="snackbar" :color="color" :timeout="timeout">
    {{ text }}
    <v-btn v-if="isError" color="#fafafa" text @click="snackbar = false"
      >Close</v-btn
    >
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    isError: false,
    snackbar: false,
    text: '',
    timeout: 2000,
  }),
  computed: {
    color: function() {
      return this.isError ? 'error' : 'info';
    },
    ...mapState({
      success: state => state.ui.showSuccess,
      error: state => state.ui.showError,
    }),
  },
  watch: {
    // Handles internal model changes.
    success(newVal) {
      this.isError = false;
      this.text = newVal;
      this.snackbar = !!newVal;
    },
    // Handles external model changes.
    error(newVal) {
      this.isError = true;
      this.text = newVal;
      this.snackbar = !!newVal;
    },
  },
};
</script>
