<template>
  <v-col cols="12" sm="12" md="10" ref="playerRef" class="player-col-wrapper">
    <div class="load-btn-wrapper">
      <v-dialog v-model="signupMessage" persistent max-width="340">
        <v-card>
          <v-card-title>
            Video list is not available
          </v-card-title>
          <v-card-text>
            Saving videos and accessing videos list is only availbale for
            registered users
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" to="/signup">
              Signup
            </v-btn>
            <v-btn color="secondary" outlined @click="signupMessage = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn width="250" color="primary" @click="handleOpenListBtn"
        >Open list</v-btn
      >
      <v-bottom-sheet v-model="showVideoList">
        <v-sheet class="text-center" height="auto">
          <v-expand-transition>
            <v-sheet v-if="selectedVideo != null" class="expand-wrapper" tile>
              <v-row class="fill-height" align="center" justify="center">
                <v-sheet
                  class="video-info-wrapper"
                  elevation="2"
                  color="#f8f6f6"
                >
                  <v-img
                    max-height="94"
                    max-width="168"
                    contain
                    :src="selectedVideoData.thumbnail"
                  ></v-img>
                  <div class="video-info-data">
                    <div>
                      <h3>{{ selectedVideoData.title }}</h3>

                      <span class="channel-name">{{
                        selectedVideoData.channelName
                      }}</span>
                    </div>

                    <v-chip color="primary" class="mt-2" outlined label>
                      <v-icon left>
                        mdi-content-save
                      </v-icon>
                      loops saved: {{ selectedVideoData.loops }}
                    </v-chip>
                  </div>
                  <div class="buttons-wrapper">
                    <v-btn
                      small
                      width="150"
                      color="secondary"
                      class="load-button"
                      @click="getLoopsAndSelect"
                    >
                      load video
                    </v-btn>
                    <v-btn
                      small
                      outlined
                      width="150"
                      color="primary"
                      class="load-button"
                      @click="deleteVideo"
                    >
                      delete video
                    </v-btn>
                  </div>
                </v-sheet>
              </v-row>
            </v-sheet>
          </v-expand-transition>
          <div class="py-3">
            <v-sheet class="mx-auto" max-width="900">
              <v-slide-group show-arrows center-active v-model="selectedVideo">
                <v-slide-item
                  v-for="n in userVideos"
                  :key="n.videoTitle"
                  v-slot:default="{ active, toggle }"
                >
                  <v-card class="ma-4" height="100" width="200" @click="toggle">
                    <div>
                      <v-img
                        :src="n.thumbnail"
                        height="100"
                        class="white--text align-center list-image"
                        v-bind:class="{ selected: active }"
                      >
                        <v-scale-transition>
                          <v-icon
                            v-if="active"
                            color="white"
                            size="48"
                            v-text="'mdi-close-circle-outline'"
                          ></v-icon>
                        </v-scale-transition>
                      </v-img>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data: () => ({
    showVideoList: false,
    signupMessage: false,
    selectedVideo: null,
  }),
  props: {
    user: Object,
    onSelect: Function,
  },
  computed: {
    selectedVideoData() {
      return this.userVideos[this.selectedVideo] || null;
    },
    ...mapState({
      userVideos: state => state.loops.userVideos,
      currentVideoLoops: state => state.loops.currentVideoLoops,
    }),
  },
  methods: {
    async getLoopsAndSelect() {
      await this.$store.dispatch('readVideoLoopsFromDb', {
        videoId: this.selectedVideoData.youtubeId,
      });
      this.onSelect(
        this.currentVideoLoops.url,
        this.currentVideoLoops.loops,
        this.selectedVideoData.youtubeId
      );
      this.showVideoList = false;
    },
    async deleteVideo() {
      await this.$store.dispatch(
        'deleteVideoFromDb',
        this.selectedVideoData.youtubeId
      );
      this.showVideoList = false;
      this.selectedVideo = null;
    },
    handleOpenListBtn() {
      if (!this.user) {
        this.signupMessage = true;
      } else {
        this.showVideoList = !this.showVideoList;
      }
    },
  },
  watch: {
    showVideoList(newState) {
      if (!newState) {
        this.selectedVideo = null;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.player-col-wrapper
  +flex-ctr()
.expand-wrapper
  padding-top: 12px
  height: 130px
  @include mq("tablet-small", "max")
    padding-top: 0
    height: 155px
  @include mq("phone-wide", "max")
    height: 230px
.video-info-wrapper
  display: flex
  width: 80%
  padding: 20px
  @include mq("tablet-small", "max")
    padding: 10px
  @include mq("tablet-wide", "max")
    width: 100%
    font-size: 14px
  @include mq("tablet-small", "max")
    flex-wrap: wrap
  @include mq("phone-wide", "max")
    width: 90%
    justify-content: center
  .buttons-wrapper
    display: flex
    flex-direction: column
    margin: 0 16px 0 auto
    .load-button
      margin-bottom: 16px
    @include mq("tablet-small", "max")
      width: 100%
      flex-direction: row
      align-items: center
      justify-content: center
      margin: 10px 0
      .load-button
        margin-bottom: 0
        margin-right: 5px
.video-info-data
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: space-between
  padding: 0 10px
  @include mq("phone-wide", "max")
    align-items: center
  > div:first-child
    display: flex
    flex-direction: column
    align-items: flex-start
    @include mq("phone-wide", "max")
      margin-top: 10px
      align-items: center
    h3
      line-height: 14px
      text-align: left
      @include mq("tablet-small", "max")
        max-width: 290px
      @include mq("phone-wide", "max")
        text-align: center
        width: 90vw
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
.list-image
  filter: grayscale(1)
  transition: filter 0.2s ease
.list-image:hover,
.list-image.selected
  filter: grayscale(0.5)
</style>
