<template>
  <v-app>
    <app-snackbar></app-snackbar>
    <app-main-bar :toggleSidebar="toggleDrawer"></app-main-bar>
    <app-nav-drawer-menu v-bind:isOpen.sync="drawer"></app-nav-drawer-menu>
    <router-view></router-view>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import { firebase } from '@/plugins/firebase';
import UserMessage from '@/components/BaseSnackBar';
import * as loadScripts from '@/assets/loadAssets';
import Footer from '@/components/Footer';
import NavigationDrawer from '@/components/NavigationDrawer';
import AppBar from '@/components/AppBar';

export default {
  name: 'App',
  data: () => ({
    drawer: false,
  }),
  components: {
    'app-snackbar': UserMessage,
    'app-footer': Footer,
    'app-nav-drawer-menu': NavigationDrawer,
    'app-main-bar': AppBar,
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
  // bind user to firestore on init
  beforeCreate() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.$store.dispatch('bindUser', user.uid);
        this.$store.dispatch('readVideoFromDb', user.uid);
      }
    });
  },
  created() {
    loadScripts.head_link(
      'https://fonts.googleapis.com/icon?family=Material+Icons'
    );
  },
};
</script>
<style lang="sass">
html
  overflow-y: auto
.primary
  background-color: #e51b23 !important
  border-color: #e51b23 !important
.secondary
  background-color: #211a1e !important
  border-color: #211a1e !important
.v-application--wrap
  min-height: auto !important
.login-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #fff
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
</style>
